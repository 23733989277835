import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import { CloseIcon } from '@material-ui/data-grid'
import React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core'
import { DialogContent } from '@material-ui/core'
import AddUser from '../AddUser'
import { AddUserDialogStateProps } from '../AssignUser'
import { useState } from 'react'
import { DataGrid, ColDef } from '@material-ui/data-grid'
import { useContext } from 'react'
import { StepWizardContext } from '../OnboardingSteps'
import { Button } from '@material-ui/core'

import { user } from '../../Types/user'

interface Props {
  openState: boolean
  handlesDialogClose: () => void
}
interface SeniorManager extends user {
  id: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    AddSrManagerButton: {
      color: '#1D9DB7',
      background: 'rgba(29, 157, 183, 0.1)',
      borderRadius: '60px',
      padding: '3px 15px',
      fontSize: '14px',
      marginBottom: '10px',
      '&:hover': {
        boxShadow: 'none',
      },
    },
  }),
)
const columns: ColDef[] = [
  { field: 'first_name', headerName: 'First name', width: 180, disableColumnMenu: true },
  { field: 'last_name', headerName: 'Last name', width: 180, disableColumnMenu: true },
  {
    field: 'email',
    headerName: 'Email',
    type: 'string',
    width: 300,
    disableColumnMenu: true,
  },
]

const SeniorManagerList: React.FC<Props> = ({ openState, handlesDialogClose }) => {
  const { globalState } = useContext(StepWizardContext)
  const [addUserDialogState, setAddUserDialogState] = useState<AddUserDialogStateProps>({
    openState: false,
    role: '',
  })

  let seniorManagers: SeniorManager[] = globalState.users
    .filter((user: user) => {
      return user['role'] === 'senior_manager'
    })
    .map((user) => {
      return { ...user, id: user['email'] }
    })
  const handleAddUserDialogClose = () => {
    setAddUserDialogState({
      openState: false,
      role: '',
    })
  }
  const handleAddUserDialogOpen = (role: string) => {
    setAddUserDialogState({
      openState: true,
      role: role,
    })
  }
  const classes = useStyles()
  return (
    <>
      <Dialog
        open={openState}
        onClose={handlesDialogClose}
        aria-labelledby="form-dialog-title"
        PaperProps={{ style: { maxWidth: '800px', minHeight: '75%' } }}
        fullWidth>
        <DialogTitle disableTypography className={classes.root}>
          <Typography variant="h6">Senior Managers</Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handlesDialogClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Button
            disableFocusRipple
            className={classes.AddSrManagerButton}
            type="button"
            variant="contained"
            onClick={() => handleAddUserDialogOpen('senior_manager')}>
            Add Senior Manager
          </Button>
          {seniorManagers.length > 0 && (
            <div style={{ height: 400, width: '100%' }}>
              <DataGrid rows={seniorManagers} columns={columns} pageSize={5} />
            </div>
          )}
        </DialogContent>
      </Dialog>
      <AddUser
        openState={addUserDialogState.openState}
        role={addUserDialogState.role}
        handlesDialogClose={handleAddUserDialogClose}
        setfieldvalue={() => {}}
      />
    </>
  )
}

export default SeniorManagerList
