import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContentText from '@material-ui/core/DialogContentText'
import { Button, createStyles, IconButton, makeStyles, Theme } from '@material-ui/core'
import React, { useContext, useState } from 'react'
import { AddUserDialogStateProps } from '../AssignUser'
import { AuthContext } from '../../Context/AuthContext'
import AddUser from '../AddUser'
import { CloseIcon } from '@material-ui/data-grid'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  }),
)

interface HelpPromptProps {
  openState: boolean
  handleOnobardUserClose: () => void
}

const UserOnboardPrompt: React.FC<HelpPromptProps> = ({ openState, handleOnobardUserClose }) => {
  const authContext = useContext(AuthContext)
  const [addUserDialogState, setAddUserDialogState] = useState<AddUserDialogStateProps>({
    openState: false,
    role: '',
    email: authContext.authState.userInfo.email || '',
  })

  const handleAddUserDialogClose = () => {
    setAddUserDialogState({
      openState: false,
      role: '',
    })
  }
  const handleAddUserDialogOpen = (role: string) => {
    handleOnobardUserClose()
    setAddUserDialogState({
      openState: true,
      role: role,
      email: authContext.authState.userInfo.email || '',
    })
  }
  const classes = useStyles()
  return (
    <div>
      <Dialog
        maxWidth={'sm'}
        open={openState}
        disableBackdropClick
        onClose={handleOnobardUserClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          Onboard yourself
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleOnobardUserClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You can onboard yourself as a Senior Manager or Tracker Owner/Manager to be a part of
            the Complius ecosystem.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color={'primary'}
            onClick={() => {
              handleAddUserDialogOpen('senior_manager')
            }}
            size={'small'}>
            SENIOR MANAGER
          </Button>
          <Button
            color={'primary'}
            onClick={() => {
              handleAddUserDialogOpen('tracker_admin')
            }}
            size={'small'}>
            TRACKER OWNER
          </Button>
          <Button
            color={'primary'}
            onClick={() => {
              handleAddUserDialogOpen('tracker_manager')
            }}
            size={'small'}>
            TRACKER MANAGER
          </Button>

          <Button onClick={handleOnobardUserClose} size={'small'}>
            SKIP
          </Button>
        </DialogActions>
      </Dialog>
      <AddUser
        openState={addUserDialogState.openState}
        role={addUserDialogState.role}
        email={addUserDialogState.email}
        handlesDialogClose={handleAddUserDialogClose}
        setfieldvalue={() => {}}
      />
    </div>
  )
}

export default UserOnboardPrompt
