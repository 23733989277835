import React from 'react'
import { Link } from 'react-router-dom'
import styled from '@material-ui/styles/styled'

interface Props {}

const LogoImage = styled(Link)({})

const Container = styled('div')({
  position: 'relative',
  width: 'initial',
  boxShadow: '0px 4px 14px rgba(0, 0, 0, 0.03)',
  padding: '10px 50px',
  marginBottom: '20px',
  backgroundColor: '#fff',
})

const Header: React.FC<Props> = () => {
  return (
    <Container>
      <LogoImage to="/">
        <img style={{ height: '30px' }} src="/logo.png" alt="Quant" />
      </LogoImage>
    </Container>
  )
}

export default Header
