import React from 'react';
import { Skeleton } from '@material-ui/lab';
import { createStyles, makeStyles } from '@material-ui/core/styles'

interface Props {
  skeletonCountArr: number[]
}

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      width: '100%',
      marginTop: '40px'
    },
  }),
);

const LoadingComponent: React.FC<Props> = ({skeletonCountArr }) => {
 const classes = useStyles()

  return (
    <div className={classes.container}>
      {skeletonCountArr.map((index) => {
        return <Skeleton width="100%" animation="wave" height={60} key={index} />;
      })}
    </div>
  );
};

export default LoadingComponent;
