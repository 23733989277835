import React, { useContext, useEffect, useState } from 'react'
import { Form, Formik, FormikHelpers } from 'formik'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Link from '@material-ui/core/Link'
import * as yup from 'yup'
import publicFetch from '../../util/publicFetch'
import LoadingButton from '../../Components/LoadingButton'
import Grid from '@material-ui/core/Grid'
import { AuthContext } from '../../Context/AuthContext'
import { toast } from 'react-hot-toast'
import { StepWizardContext } from '../../Components/OnboardingSteps'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import FormCard from '../../Components/FormCard/FormCard'
import sendOtpHandler from '../../handler/sendOtpHandler'
import resendOtpHandler from '../../handler/resendOtpHandler'
import Helmet from 'react-helmet'
import Checkbox from '@material-ui/core/Checkbox'
import { FormControlLabel, FormHelperText } from '@material-ui/core'
import { CheckBox } from '@material-ui/icons'
import CheckBoxInput from '../../Components/FormikMuiFields/CheckBox'

export interface Values {
  email: string
  otp: string
  terms_and_conditions: boolean
}

interface Props extends RouteComponentProps {}

const useStyles = makeStyles((theme) => ({
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  link: {
    margin: theme.spacing(0, 1, 0, 0),
  },
}))

const VerifyOTP: React.FC<Props> = ({ match: { path }, history: { push } }) => {
  const [emailValidated, setEmailValidated] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { dispatch } = useContext(StepWizardContext)
  const authContext = useContext(AuthContext)
  const [OTPResendTries, setOTPResendTries] = useState<number>(3)
  const classes = useStyles()

  const validationSchema = yup.object().shape({
    email: yup.string().email().required(),
    otp: yup
      .string()
      .required()
      .min(6, 'Must be exactly 6 digits')
      .max(6, 'Must be exactly 6 digits'),
    terms_and_conditions: yup
      .boolean()
      .oneOf([true], 'Terms & Conditions must be accepted to proceed'),
  })

  const onSubmit = (values: Values, { resetForm, setErrors }: FormikHelpers<Values>) => {
    const headers = { 'Access-Control-Allow-Origin': '*' }
    setIsLoading(true)
    publicFetch
      .post(`/api_client_onboard/validate_otp`, values, { headers })
      .then((res: any) => {
        authContext.setAuthState({
          accessToken: res.data.token.access,
          refreshToken: res.data.token.refresh,
          expiresAt: res.data.token.expiry,
          userInfo: { email: values.email },
        })
        resetForm()
        toast.success('OTP Verified Successfully!')
        dispatch({
          type: 'SUCCESS_LOGIN',
          payload: {
            OTPVerified: true,
            loginStep: {
              verifiedEmail: values.email,
            },
          },
        })
        push(`/onboarding/add-company`)
      })
      .catch((error: any) => {
        if (error.response && error.response.status === 400) {
          setErrors(error.response.data.error)
        } else {
          console.error('There was an error!', error)
          toast.error('Something went wrong. Try again later.')
        }
        dispatch({
          type: 'ERROR_LOGIN',
        })
        setIsLoading(false)
      })
  }

  useEffect(() => {
    if (authContext.isAuthenticated()) {
      push(`/onboarding/add-company`)
    } else {
      dispatch({
        type: 'RESET',
      })
    }
  }, [])
  return (
    <Formik
      initialValues={{
        email: '',
        otp: '',
        terms_and_conditions: false,
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}>
      {({
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
        isSubmitting,
        isValid,
        dirty,
        setErrors,
        setFieldValue,
      }) => (
        <Container component="main" maxWidth="sm">
          <CssBaseline />
          <Helmet>
            <title>Onboarding: Login</title>
          </Helmet>
          <FormCard header="Sign In">
            <div>
              <Form className={classes.form} noValidate>
                <TextField
                  size="medium"
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoFocus={!emailValidated}
                  autoComplete="off"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onKeyDown={(event) => {
                    if (
                      !emailValidated &&
                      dirty &&
                      !Boolean(errors.email) &&
                      event.key === 'Enter'
                    ) {
                      sendOtpHandler(values, setErrors, setIsLoading, setEmailValidated)
                      event.preventDefault()
                    }
                  }}
                  value={values.email}
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email ? errors.email : ''}
                />
                {emailValidated && (
                  <>
                    {OTPResendTries > 0 && (
                      <>
                        <Grid container justify="flex-end">
                          <Link
                            href="#"
                            onClick={() =>
                              resendOtpHandler(
                                values,
                                setErrors,
                                setFieldValue,
                                setEmailValidated,
                                setOTPResendTries,
                              )
                            }
                            className={classes.link}>
                            Resend OTP
                          </Link>
                        </Grid>
                      </>
                    )}
                    <TextField
                      size="medium"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="otp"
                      label="OTP"
                      name="otp"
                      autoComplete="off"
                      autoFocus={emailValidated}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.otp && Boolean(errors.otp)}
                      helperText={touched.otp ? errors.otp : ''}
                    />
                    <CheckBoxInput
                      color="primary"
                      name="terms_and_conditions"
                      label={
                        <>
                          I have read and accept the{' '}
                          <Link
                            target="_blank"
                            href="https://toolkitagreement.complius.com"
                            className={classes.link}>
                            Terms & Conditions
                          </Link>
                        </>
                      }
                    />
                    <LoadingButton
                      loading={isLoading}
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      size="large"
                      className={classes.submit}
                      onChange={handleChange}
                      disabled={!(dirty && isValid) || isLoading}>
                      Submit
                    </LoadingButton>
                  </>
                )}
                {!emailValidated && (
                  <LoadingButton
                    loading={isLoading}
                    fullWidth
                    variant="contained"
                    color="primary"
                    size="large"
                    className={classes.submit}
                    onClick={() =>
                      sendOtpHandler(values, setErrors, setIsLoading, setEmailValidated)
                    }
                    disabled={
                      isLoading || isSubmitting || Boolean(errors.email) || values.email === ''
                    }>
                    Send OTP
                  </LoadingButton>
                )}
              </Form>
            </div>
          </FormCard>
        </Container>
      )}
    </Formik>
  )
}

export default withRouter(VerifyOTP)
