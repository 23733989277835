import React, { useState } from 'react'
import { Alert, AlertTitle } from '@material-ui/lab'
import styled from '@material-ui/styles/styled'
import Button from '@material-ui/core/Button'
import { LinearProgress } from '@material-ui/core'
import { useEffect } from 'react'
import { FetchContext } from '../../Context/FetchContext'
import { useContext } from 'react'
import Video from '../../Components/Video'
import { Helmet } from 'react-helmet'
import { toast } from 'react-hot-toast'
import { AxiosInstance } from 'axios'
import { RouteComponentProps } from 'react-router-dom'
import { onBoardingStepTypes } from '../../Types/onBoardingStepTypes'
import { StepWizardContext } from '../../Components/OnboardingSteps'
import { AuthContext, AuthContextProps } from '../../Context/AuthContext'
import { user } from '../../Types/user'
import { activity } from '../../Types/activity'

interface Props extends RouteComponentProps<{ step: onBoardingStepTypes }> {}

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  marginTop: 20,
})

const VideoContainer = styled('div')({
  marginTop: 20,
})

const CustomButton = styled(Button)({
  marginTop: 20,
  marginBottom: 20,
  color: 'white',
  '&:hover': {
    color: 'white',
    background: '#1D9DB7',
  },
})

const onboardCompany = (fetchContext: { authAxios: AxiosInstance }) => {
  fetchContext.authAxios
    .post(`/api_client_onboard/onboarding_mas`)
    .then((res) => {})
    .catch((error) => {
      console.log(error)
    })
}

const retryOnboard = (
  fetchContext: { authAxios: AxiosInstance },
  authContext: AuthContextProps,
  onboardingStatus: string,
  setOnboardingStatus: React.Dispatch<React.SetStateAction<string>>,
  push: (path: string, state?: unknown) => void,
  retriesLeft: number,
) => {
  onboardCompany(fetchContext)
  var timer = setInterval(function () {
    if (onboardingStatus !== 'Success') {
      fetchContext.authAxios
        .get(`/api_client_onboard/onboarding_status`)
        .then((res) => {
          let status: string = res.data.result.status
          if (status === 'Exception') {
            if (retriesLeft > 0) {
              retryOnboard(
                fetchContext,
                authContext,
                onboardingStatus,
                setOnboardingStatus,
                push,
                retriesLeft - 1,
              )
            } else {
              setOnboardingStatus('Exception')
              notifyException(fetchContext)
              clearInterval(timer)
            }
            clearInterval(timer)
          } else if (status === 'Failed') {
            if (typeof res.data.result.error === 'object') {
              toast.error(Object.values(res.data.result.error).join(', '))
            } else {
              toast.error(res.data.result.error)
            }
            push('/onboarding/add-compliances')
            clearInterval(timer)
          } else if (status === 'Success') {
            debugger
            setOnboardingStatus('Success')
            authContext.clearTokens()
            clearInterval(timer)
          }
        })
        .catch((error) => {
          console.log(error)
          clearInterval(timer)
        })
    } else {
      clearInterval(timer)
    }
  }, 10000)
}

const notifyException = (fetchContext: { authAxios: AxiosInstance }) => {
  fetchContext.authAxios
    .post(`/api_client_onboard/onboard_exception`)
    .then((res) => {})
    .catch((error) => {
      console.log(error)
    })
}
const Success: React.FC<Props> = ({ history: { push } }) => {
  const [onboardingStatus, setOnboardingStatus] = useState<string>('Inprocess')
  const fetchContext = useContext(FetchContext)
  const authContext = useContext(AuthContext)
  const { globalState, dispatch } = useContext(StepWizardContext)
  useEffect(() => {
    if (!globalState.dataSynced) {
      // For page refresh or directly entering success url
      // Validate all previous steps are completed
      fetchContext.authAxios
        .get(`/api_client_onboard/get_onboard_info`)
        .then((res) => {
          dispatch({ type: 'SYNC_DATA', payload: res.data[authContext.authState.userInfo.email] })

          if ('cmp_info' in res.data[authContext.authState.userInfo.email]) {
            if (!('name' in res.data[authContext.authState.userInfo.email].cmp_info)) {
              push('/onboarding/add-company')
              return
            }
          } else {
            push('/onboarding/add-company')
            return
          }

          let resKeys: string[] = Object.keys(res.data[authContext.authState.userInfo.email])
          if (!['loc_info', 'user_info', 'activity_mapping'].every((v) => resKeys.includes(v))) {
            push('/onboarding/add-compliances')
            return
          }
          if (Object.keys(res.data[authContext.authState.userInfo.email].loc_info).length === 0) {
            push('/onboarding/add-compliances')
            return
          }

          let userList: user[] = Object.values(
            res.data[authContext.authState.userInfo.email].user_info,
          )
          let seniorManagers: user[] = userList.filter((user: user) => {
            return user['role'] === 'senior_manager'
          })

          if (seniorManagers.length === 0) {
            push('/onboarding/add-compliances')
            return
          }
          let masterUANList: string[] = res.data[
            authContext.authState.userInfo.email
          ].activities.map((a: activity) => a.UAN)
          for (var location of Object.keys(
            res.data[authContext.authState.userInfo.email].loc_info,
          )) {
            if (location in res.data[authContext.authState.userInfo.email].activity_mapping) {
              let mappedUANs: string[] = Object.keys(
                res.data[authContext.authState.userInfo.email]['activity_mapping'][location],
              )
              if (!masterUANList.every((UAN) => mappedUANs.includes(UAN))) {
                push('/onboarding/add-compliances')
                return
              }
            } else {
              push('/onboarding/add-compliances')
              return
            }
          }
          retryOnboard(fetchContext, authContext, onboardingStatus, setOnboardingStatus, push, 3)
        })
        .catch((error) => {
          console.log(error)
        })
    } else {
      retryOnboard(fetchContext, authContext, onboardingStatus, setOnboardingStatus, push, 3)
    }
  }, [fetchContext])
  return (
    <Container>
      <div>
        {onboardingStatus === 'Success' && (
          <>
            <Helmet>
              <title>Onboarding: Success</title>
            </Helmet>
            <Alert severity="success">
              <AlertTitle>Onboarding Complete!</AlertTitle>
              The login details have been mailed to all the users.
              <br />
              <span style={{ fontSize: '11px' }}>
                PS: Sometimes the emails end up in spam the first time based on your Org's filters.
              </span>
            </Alert>
          </>
        )}
        {onboardingStatus === 'Inprocess' && (
          <>
            <Helmet>
              <title>Onboarding: Inprocess</title>
            </Helmet>
            <Alert severity="info">
              <AlertTitle>We're getting things ready!</AlertTitle>
              Please do not refresh the page <br />
              Please feel free to watch a quick video about Complius!
            </Alert>
            <LinearProgress />
          </>
        )}
        {(onboardingStatus === 'Exception' || onboardingStatus === 'Failed') && (
          <>
            <Helmet>
              <title>Onboarding: Failed</title>
            </Helmet>
            <Alert severity="error">
              <AlertTitle>Something Went Wrong!</AlertTitle>
              Our support team will contact you.
            </Alert>
          </>
        )}
      </div>

      <VideoContainer>
        <Video />
      </VideoContainer>
      {onboardingStatus === 'Success' && (
        <CustomButton
          variant="contained"
          onClick={() => {
            window.open(process.env.REACT_APP_API_URL, '_blank')
          }}>
          Go to Complius
        </CustomButton>
      )}
    </Container>
  )
}

export default Success
