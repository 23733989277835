import React, { createContext, useState } from 'react'

interface ProviderProps {
  children: React.ReactNode
}

export interface ContextProps {
  selectedLocations: string[]
  setSelectedLocations: (locations: string[]) => void
}

const SelectedLocationContext = createContext<ContextProps>({
  selectedLocations: [],
  setSelectedLocations: ([]) => {},
})
const { Provider } = SelectedLocationContext

const LocationProvider = ({ children }: ProviderProps) => {
  const [selectedLocations, setLocations] = useState<string[]>([])

  const setSelectedLocations = (locations: string[]) => {
    setLocations(locations)
  }

  return (
    <Provider
      value={{
        selectedLocations,
        setSelectedLocations: (location: string[]) => setSelectedLocations(location),
      }}>
      {children}
    </Provider>
  )
}

export { SelectedLocationContext, LocationProvider }
