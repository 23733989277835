import React, { ReactNode } from 'react'

import { Field, ErrorMessage, FieldInputProps } from 'formik'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(2, 0, 2, 0),
  },
}))

export interface SelectItem {
  label: string
  value: string
}

interface SelectInputProps {
  name: string
  items: SelectItem[]
  label: string
  required?: boolean
  fullWidth?: boolean
  variant?: string
  error: boolean
}

interface SelectMuiProps extends FieldInputProps<string> {
  children: ReactNode
  label: string
  required: boolean
  fullWidth: boolean
  variant: 'outlined' | 'filled' | 'standard' | undefined
  errorText: string
  error?: boolean
}

const SelectMui: React.FC<SelectMuiProps> = ({
  children,
  label,
  value,
  name,
  required,
  variant,
  errorText,
  error,
  fullWidth,
  onChange,
  onBlur,
}) => {
  const classes = useStyles()
  return (
    <FormControl
      error={error}
      fullWidth={fullWidth}
      variant={variant}
      required={required}
      className={classes.formControl}>
      <InputLabel id="demo-simple-select-outlined-label">{label}</InputLabel>
      <Select
        id="demo-simple-select-outlined"
        labelId="demo-simple-select-outlined-label"
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        label={label}>
        {children}
      </Select>
      <FormHelperText>{errorText}</FormHelperText>
    </FormControl>
  )
}

const SelectInput: React.FC<SelectInputProps> = ({
  name,
  items,
  label,
  error = false,
  required = true,
  fullWidth,
  variant = 'outlined',
}) => {
  return (
    <Field
      as={SelectMui}
      name={name}
      label={label}
      variant={variant}
      fullWidth={fullWidth}
      required={required}
      error={error}
      errorText={<ErrorMessage name={name} />}>
      {items.map((item) => (
        <MenuItem key={item.value} value={item.value}>
          {item.label}
        </MenuItem>
      ))}
    </Field>
  )
}

export default SelectInput
