export const getOrdinalNum = (number: number) => {
  let selector: number

  if (number <= 0) {
    selector = 4
  } else if ((number > 3 && number < 21) || number % 10 > 3) {
    selector = 0
  } else {
    selector = number % 10
  }

  return ['th', 'st', 'nd', 'rd', ''][selector]
}

export const getFormattedDate = (date: Date | null) => {
  if (date === null) {
    return ''
  }
  let month = (date.getMonth() + 1).toString()
  let day = date.getDate().toString()
  // Pad zero
  month = month.length < 2 ? '0' + month : month.toString()
  day = day.length < 2 ? '0' + day : day
  return date.getFullYear() + '-' + month + '-' + day
}
