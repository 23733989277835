import React, { useState } from 'react'
import notificationIconBlue from '../../assets/notifications_blue.svg'
import notificationIconRed from '../../assets/notifications_red.svg'
import notificationIconAmber from '../../assets/notifications_amber.svg'
import { FetchContext } from '../../Context/FetchContext'
import { useContext } from 'react'
import { data } from '../Notification/NotificationCard'
import { styled } from '@material-ui/core/styles'
import NotificationCard from '../Notification/NotificationCard'
import { useEffect } from 'react'
import { getOrdinalNum } from '../../util/dateUtil'
import ToolTip from '../ToolTip'
import CenterContent from '../CenterContent'
import Skeleton from '../../Components/Skeleton'
import { StepWizardContext } from '../OnboardingSteps'
import { NotificationObject } from '../../Types/notification'

interface Props {}

const color = {
  blue: '#2196F3',
  amber: '#FF9800',
  red: '#F44336',
}

const Container = styled('div')({
  marginTop: '20px',
})

const Header = styled('h3')({
  margin: 0,
  fontFamily: 'Nunito',
  padding: '20px',
})

const getFormattedNotifications = (notifications: NotificationObject) => {
  let data: data[] = []
  let index: number = 0
  for (let name in notifications) {
    if (notifications[name].type === 'Reminder') {
      data.push({
        id: index,
        img: notificationIconBlue,
        label: name,
        desc: 'Email notification for upcoming activities',
        time: `${Math.abs(notifications[name].trigger_days)} Days Before`,
        color: color['blue'],
      })
    } else if (notifications[name].type === 'Escalation') {
      data.push({
        id: index,
        img: notificationIconRed,
        label: name,
        desc: 'Email notification for expired activities',
        time: `${Math.abs(notifications[name].trigger_days)} Days After`,
        color: color['red'],
      })
    } else if (notifications[name].type === 'Overdue') {
      data.push({
        id: index,
        img: notificationIconAmber,
        label: name,
        desc: 'Email notification sent to Senior Manager for overdue activities',
        time: (
          <span>
            {Math.abs(notifications[name].trigger_days)}
            <sup>{getOrdinalNum(Math.abs(notifications[name].trigger_days))}</sup> Of Every Month
          </span>
        ),
        color: color['amber'],
      })
    }
    index++
  }
  return data
}
const NotificationDetails: React.FC<Props> = () => {
  const fetchContext = useContext(FetchContext)
  const [notificationData, setNotificationData] = useState<data[]>([])
  const [notificationLoading, setNotificationLoading] = useState<boolean>(true)
  const { globalState, dispatch } = useContext(StepWizardContext)

  useEffect(() => {
    if (Object.keys(globalState.notification).length === 0) {
      fetchContext.authAxios
        .get(`/api_client_onboard/get_notification_info`)
        .then((res) => {
          setNotificationLoading(false)
          let notifications: NotificationObject = res.data.result
          setNotificationData(getFormattedNotifications(notifications))
          dispatch({ type: 'SET_NOTIFICATIONS', payload: notifications })
        })
        .catch((error) => {
          setNotificationLoading(false)
          console.log(error)
        })
    } else {
      setNotificationData(getFormattedNotifications(globalState.notification))
      setNotificationLoading(false)
    }
  }, [fetchContext])
  return (
    <Container>
      {notificationLoading ? (
        <Skeleton skeletonCountArr={[1, 2, 3]} />
      ) : (
        <>
          <Header>
            <CenterContent>
              Notifications <ToolTip id="notifications" />
            </CenterContent>
          </Header>
          <NotificationCard data={notificationData} />
        </>
      )}
    </Container>
  )
}

export default NotificationDetails
