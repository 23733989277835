import React from 'react'
import { styled } from '@material-ui/core/styles'

type details = {
  id: number
  name: string
}

export type data = {
  id: number
  img: any
  label: string
  desc?: string
  details?: details[]
}

interface Props {
  data: data[]
}

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
})

const ContainerInner = styled('div')({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  marginBottom: '20px',
})

const ImgContainer = styled('img')({
  margin: '0 5px 0 0',
})

const TextContainer = styled('div')({
  margin: '0 10px',
})

const Label = styled('div')({
  margin: '0 2px',
  fontFamily: 'Nunito',
  fontStyle: 'normal',
  fontWeight: 900,
  fontSize: '11px',
  lineHeight: '16px',
  letterSpacing: '0.2px',
  textTransform: 'uppercase',
  color: '#8E8E8E',
})

const Description = styled('div')({
  margin: '0 2px',
  fontFamily: 'Nunito',
  fontStyle: 'normal',
  fontWeight: 800,
  fontSize: '14px',
  lineHeight: '20px',
  letterSpacing: '0.1px',
  color: '#323C47',
})

const DetailsContainer = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
})

const DetailsItem = styled('div')({
  background: '#F7F7F7',
  borderRadius: '50px',
  fontFamily: 'Nunito',
  fontStyle: 'normal',
  fontWeight: 'bold',
  fontSize: '13px',
  lineHeight: '14px',
  letterSpacing: '0.1px',
  color: '#323C47',
  padding: '5px 10px',
  margin: '0 10px 10px 0',
})

const CardItem: React.FC<Props> = ({ data }) => {
  return (
    <Container>
      {data.map(({ desc, img, label, id, details }) => {
        if (details) {
          return (
            <ContainerInner key={id}>
              <ImgContainer src={img} alt="" />
              <TextContainer>
                <Label>{label}</Label>
                <DetailsContainer>
                  {details.map(({ id, name }) => (
                    <DetailsItem key={id}>{name}</DetailsItem>
                  ))}
                </DetailsContainer>
              </TextContainer>
            </ContainerInner>
          )
        }

        return (
          <ContainerInner key={id}>
            <ImgContainer src={img} alt="" />
            <TextContainer>
              <Label>{label}</Label>
              <Description>{desc}</Description>
            </TextContainer>
          </ContainerInner>
        )
      })}
    </Container>
  )
}

export default CardItem
