import React, { useEffect } from 'react'
import Helmet from 'react-helmet'
import { Button, Grid } from '@material-ui/core'
import BasicInformation from '../../Components/review/BasicInformation'
import LocationDetails from '../../Components/review/LocationDetails'
import UserDetails from '../../Components/review/UserDetails'
import NotificationDetails from '../../Components/review/NotificationDetails'
import { makeStyles } from '@material-ui/core/styles'
import { AuthContext } from '../../Context/AuthContext'
import { FetchContext } from '../../Context/FetchContext'
import { StepWizardContext } from '../../Components/OnboardingSteps'
import { useContext } from 'react'
import { onBoardingStepTypes } from '../../Types/onBoardingStepTypes'
import { RouteComponentProps } from 'react-router-dom'
import Skeleton from '../../Components/Skeleton'

import helperText from '../../data/helperText'
import { user } from '../../Types/user'
import { activity } from '../../Types/activity'

interface Props extends RouteComponentProps<{ step: onBoardingStepTypes }> {}

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100vw',
    padding: '0 100px',
  },
}))

const ReviewOnboarding: React.FC<Props> = ({ history: { push } }) => {
  const authContext = useContext(AuthContext)
  const fetchContext = useContext(FetchContext)
  const { globalState, dispatch } = useContext(StepWizardContext)

  const classes = useStyles()
  useEffect(() => {
    if (!globalState.dataSynced) {
      fetchContext.authAxios
        .get(`/api_client_onboard/get_onboard_info`)
        .then((res) => {
          dispatch({ type: 'SYNC_DATA', payload: res.data[authContext.authState.userInfo.email] })

          if ('cmp_info' in res.data[authContext.authState.userInfo.email]) {
            if (!('name' in res.data[authContext.authState.userInfo.email].cmp_info)) {
              push('/onboarding/add-company')
              return
            }
          } else {
            push('/onboarding/add-company')
            return
          }

          let resKeys: string[] = Object.keys(res.data[authContext.authState.userInfo.email])
          if (!['loc_info', 'user_info', 'activity_mapping'].every((v) => resKeys.includes(v))) {
            push('/onboarding/add-compliances')
            return
          }
          if (Object.keys(res.data[authContext.authState.userInfo.email].loc_info).length === 0) {
            push('/onboarding/add-compliances')
            return
          }

          let userList: user[] = Object.values(
            res.data[authContext.authState.userInfo.email].user_info,
          )
          let seniorManagers: user[] = userList.filter((user: user) => {
            return user['role'] === 'senior_manager'
          })

          if (seniorManagers.length === 0) {
            push('/onboarding/add-compliances')
            return
          }
          let masterUANList: string[] = res.data[
            authContext.authState.userInfo.email
          ].activities.map((a: activity) => a.UAN)
          for (var location of Object.keys(
            res.data[authContext.authState.userInfo.email].loc_info,
          )) {
            if (location in res.data[authContext.authState.userInfo.email].activity_mapping) {
              let mappedUANs: string[] = Object.keys(
                res.data[authContext.authState.userInfo.email]['activity_mapping'][location],
              )
              if (!masterUANList.every((UAN) => mappedUANs.includes(UAN))) {
                push('/onboarding/add-compliances')
                return
              }
            } else {
              push('/onboarding/add-compliances')
              return
            }
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
    const data = helperText.filter((el) => el)
    // @ts-ignore
    dispatch({ type: 'SET_HELPER_MODAL_DATA', payload: data[3]['review-onboarding'] })
  }, [authContext, fetchContext])

  const submitHandler = () => {
    dispatch({
      type: 'NEXT_STEP',
    })
    push('/onboarding/success')
  }

  return (
    <div>
      <Helmet>
        <title>Onboarding: Review</title>
      </Helmet>
      <Grid justify="center" container spacing={2} className={classes.container}>
        {globalState.dataSynced ? (
          <>
            <Grid item xs={3}>
              <BasicInformation />
            </Grid>
            <Grid item xs={3}>
              <LocationDetails />
            </Grid>
            <Grid item xs={3}>
              <UserDetails />
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={3}>
              <Skeleton skeletonCountArr={[1, 2, 3]} />
            </Grid>
            <Grid item xs={3}>
              <Skeleton skeletonCountArr={[1, 2, 3]} />
            </Grid>
            <Grid item xs={3}>
              <Skeleton skeletonCountArr={[1, 2, 3]} />
            </Grid>
          </>
        )}
        <Grid item xs={3}>
          <NotificationDetails />
        </Grid>
      </Grid>
      <Grid justify="center" container spacing={2} className={classes.container}>
        <Grid item xs={4}></Grid>
        <Grid item xs={4}>
          <div style={{ textAlign: 'center' }}>
            <Button
              style={{ color: '#1D9DB7', margin: '10px' }}
              onClick={() => {
                push('/onboarding/add-compliances')
              }}>
              Back
            </Button>
          </div>
          <Button
            disabled={!globalState.dataSynced}
            variant="contained"
            color="primary"
            size="large"
            fullWidth
            onClick={submitHandler}>
            Complete Onboarding
          </Button>
        </Grid>
        <Grid item xs={4}></Grid>
      </Grid>
    </div>
  )
}

export default ReviewOnboarding
