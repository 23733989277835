import apiClient from '../util/publicFetch'
import { toast } from 'react-hot-toast'
import { Values } from '../Pages/VerifyOTP'
import { Dispatch, SetStateAction } from 'react'

const handleEmailSubmit = (
  values: Values,
  setErrors: (fields: { [field: string]: string }) => void,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  setEmailValidated: Dispatch<SetStateAction<boolean>>,
) => {
  const headers = { 'Access-Control-Allow-Origin': '*' }
  setIsLoading(true)
  apiClient
    .post(`/api_client_onboard/send_otp`, values, { headers })
    .then(() => {
      setEmailValidated(true)
      setIsLoading(false)
      toast.success('OTP Sent Successfully!')
    })
    .catch((error: any) => {
      setEmailValidated(false)
      if (error.response && error.response.status === 400) {
        setErrors(error.response.data.error)
      } else {
        console.error('There was an error!', error)
        toast.error('Something went wrong. Try again later.')
      }
      setIsLoading(false)
    })
}

export default handleEmailSubmit
