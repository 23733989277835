import React from 'react'
import FormCard from '../FormCard/FormCard'
import CardItem from './CardItem'
import reviewAdminIcon from '../../assets/review_admin.svg'
import reviewRegularUserIcon from '../../assets/review_regular_user.svg'
import { styled } from '@material-ui/core/styles'
import { StepWizardContext } from '../OnboardingSteps'
import { data } from './CardItem'
import { useContext } from 'react'
import { userRoles } from '../../util/userRoles'
interface Props {}

const Container = styled('div')({})

const UserDetails: React.FC<Props> = () => {
  const { globalState } = useContext(StepWizardContext)
  let data: data[] = []
  globalState.users.forEach((user, index) => {
    data.push({
      id: index,
      label: userRoles[user.role],
      desc: user.first_name + ' ' + user.last_name,
      img: user.role === 'senior_manager' ? reviewAdminIcon : reviewRegularUserIcon,
    })
  })
  return (
    <Container>
      <FormCard isReviewCard header="User Information">
        <CardItem data={data} />
      </FormCard>
    </Container>
  )
}

export default UserDetails
