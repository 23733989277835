import React, { createContext, useState } from 'react'
import { useHistory } from 'react-router-dom'

interface UserInfo {
  email: string
}
interface AuthState {
  accessToken: string
  refreshToken: string
  expiresAt: string
  userInfo: UserInfo
}

interface AuthProviderProps {
  children: React.ReactNode
}

export interface AuthContextProps {
  authState: AuthState
  setAuthState: (authInfo: AuthState) => void
  logout: () => void
  clearTokens: () => void
  isAuthenticated: () => boolean
}

const defaultAuthState: AuthState = {
  accessToken: '',
  refreshToken: '',
  expiresAt: '',
  userInfo: { email: '' },
}

const AuthContext = createContext<AuthContextProps>({
  isAuthenticated: () => {
    return false
  },
  logout: () => {},
  clearTokens: () => {},
  authState: defaultAuthState,
  setAuthState: (defaultAuthState) => {},
})
const { Provider } = AuthContext

const AuthProvider = ({ children }: AuthProviderProps) => {
  const history = useHistory()

  const accessToken = localStorage.getItem('accessToken')
  const refreshToken = localStorage.getItem('refreshToken')
  const userInfo = localStorage.getItem('userInfo')
  const expiresAt = localStorage.getItem('expiresAt')

  const [authState, setAuthState] = useState<AuthState>({
    accessToken: accessToken ? accessToken : '',
    refreshToken: refreshToken ? refreshToken : '',
    expiresAt: expiresAt ? expiresAt : '',
    userInfo: userInfo ? JSON.parse(userInfo) : {},
  })

  //
  const setAuthInfo = ({ accessToken, refreshToken, userInfo, expiresAt }: AuthState) => {
    localStorage.setItem('accessToken', accessToken)
    localStorage.setItem('refreshToken', refreshToken)
    localStorage.setItem('userInfo', JSON.stringify(userInfo))
    localStorage.setItem('expiresAt', expiresAt)

    setAuthState({
      accessToken,
      refreshToken,
      userInfo,
      expiresAt,
    })
  }

  const clearTokens = () => {
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('userInfo')
    localStorage.removeItem('expiresAt')
  }
  const logout = () => {
    clearTokens()
    setAuthState(defaultAuthState)
    history.push('/onboarding/login')
  }

  //
  const isAuthenticated = () => {
    if (!localStorage.getItem('accessToken') || !authState.accessToken) {
      return false
    }

    // return new Date().getTime() / 1000 < parseInt(authState.expiresAt)
    return true
  }

  return (
    <Provider
      value={{
        authState,
        setAuthState: (authInfo: AuthState) => setAuthInfo(authInfo),
        logout,
        clearTokens,
        isAuthenticated,
      }}>
      {children}
    </Provider>
  )
}

export { AuthContext, AuthProvider }
