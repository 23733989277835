import React, { useContext } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { MobileStepper, Modal, Theme, useTheme } from '@material-ui/core'
import SwipeableViews from 'react-swipeable-views'
import Button from '@material-ui/core/Button'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons'
import HelperContentLayout from './HelperContentLayout'
import { StepWizardContext } from './OnboardingSteps'

interface Props {}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      width: 400,
      backgroundColor: 'white',
      boxShadow: theme.shadows[5],
      padding: '20px 30px',
      outline: 'none',
      borderRadius: '4px',
    },
    root: {
      maxWidth: 400,
      flexGrow: 1,
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      height: 50,
      paddingLeft: theme.spacing(4),
      backgroundColor: theme.palette.background.default,
    },
    img: {
      height: 255,
      display: 'block',
      maxWidth: 400,
      overflow: 'hidden',
      width: '100%',
    },
  }),
)

function SwipeableTextMobileStepper() {
  const classes = useStyles()
  const theme = useTheme()
  const { globalState, dispatch } = useContext(StepWizardContext)

  const maxSteps = globalState.helperTextData.length

  const handleNext = () => {
    dispatch({ type: 'UPDATE_ACTIVE_STEP_HELPER_MODAL', payload: globalState.activeStep + 1 })
  }

  const handleBack = () => {
    dispatch({ type: 'UPDATE_ACTIVE_STEP_HELPER_MODAL', payload: globalState.activeStep - 1 })
  }

  const handleStepChange = (step: number) => {
    dispatch({ type: 'UPDATE_ACTIVE_STEP_HELPER_MODAL', payload: step })
  }

  return (
    <div className={classes.root}>
      <SwipeableViews
        index={globalState.activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents>
        {globalState.helperTextData.map((el) => (
          <>
            <HelperContentLayout
              /*
         // @ts-ignore */
              body={el.detailedText}
              /*
   // @ts-ignore */
              title={el.titleText}
            />
          </>
        ))}
      </SwipeableViews>
      <MobileStepper
        steps={maxSteps}
        position="static"
        variant="dots"
        activeStep={globalState.activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={globalState.activeStep === maxSteps - 1}>
            Next
            {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={globalState.activeStep === 0}>
            {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            Back
          </Button>
        }
      />
    </div>
  )
}

const HelperModal: React.FC<Props> = () => {
  const classes = useStyles()
  const {
    globalState: { isHelperModalOpen },
    dispatch,
  } = useContext(StepWizardContext)

  const handleClose = () => {
    dispatch({ type: 'UPDATE_HELPER_MODAL', payload: false })
  }

  const body = (
    <div className={classes.paper}>
      <SwipeableTextMobileStepper />
    </div>
  )

  return (
    <div>
      <Modal
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 2000,
        }}
        open={isHelperModalOpen}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description">
        {body}
      </Modal>
    </div>
  )
}

export default HelperModal
