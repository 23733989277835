import React, { useContext } from 'react'
import FormCard from '../FormCard/FormCard'
import CardItem from './CardItem'
import reviewLocationIcon from '../../assets/review_location.svg'
import { styled } from '@material-ui/core/styles'
import { StepWizardContext } from '../OnboardingSteps'
import { data } from './CardItem'

interface Props {}

const Container = styled('div')({})

const LocationDetails: React.FC<Props> = () => {
  const { globalState } = useContext(StepWizardContext)
  let data: data[] = []
  globalState.locations.forEach((location, index) => {
    data.push({
      id: index,

      label: location.office_type.map((o) => o.name).join(', '),
      desc: location.name,
      img: reviewLocationIcon,
    })
  })

  return (
    <Container>
      <FormCard isReviewCard header="Location Information">
        <CardItem data={data} />
      </FormCard>
    </Container>
  )
}

export default LocationDetails
