import * as React from 'react'
import {
  DataGrid,
  ColDef,
  CellParams,
  GridToolbar,
  SelectionModelChangeParams,
} from '@material-ui/data-grid'
import Paper from '@material-ui/core/Paper'
import StatusText from '../StatusText/StatusText'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import { useContext, useEffect, useState } from 'react'
import AssignUser from '../AssignUser'
import { StepWizardContext } from '../../Components/OnboardingSteps'
import { activity } from '../../Types/activity'
import { SelectedLocationContext } from '../../Context/SelectedLocations'
import customOverlay from './customOverlay'
import { user } from '../../Types/user'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { Switch, Tooltip } from '@material-ui/core'
import CenterContent from '../CenterContent'
import ToolTip from '../ToolTip'
import CallOut from '../CallOut'
import toast from 'react-hot-toast'
import UserOnboardPrompt from '../UserOnboardPrompt'
import { removeItem } from '../../util/common'

const useStyles = makeStyles({
  table: {
    minWidth: '100px',
  },
  assignUserButton: {
    color: '#1D9DB7',
    background: 'rgba(29, 157, 183, 0.1)',
    borderRadius: '60px',
    padding: '3px 15px',
    fontSize: '14px',
    // marginLeft: '30px',
    '&:hover': {
      boxShadow: 'none',
    },
  },
  statContainer: {
    display: 'flex',
    justifyContent: 'space-between',

    marginBottom: '20px',
    height: 'auto',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  counterContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  checkboxText: {
    fontFamily: 'Roboto',
    letterSpacing: '1px',
    textTransform: 'uppercase',
    color: '#192A3E',
    opacity: '0.5',
    fontSize: '5px',
    lineHeight: '10px',
    fontStyle: 'normal',
    fontWeight: 'bold',
    marginRight: '10px',
  },
  switchText: {
    fontFamily: 'Roboto',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'right',
    letterSpacing: '1px',
    textTransform: 'uppercase',
    color: '#192A3E',
    opacity: '1',
    fontSize: '10px',
    lineHeight: '20px',
    fontStyle: 'normal',
    fontWeight: 'bold',
    marginRight: '10px',
  },
  switchStyles: {
    marginRight: '10px',
  },
  noMaxWidth: {
    maxWidth: '1000px',
  },
})

interface Props {
  selectedActivities: (string | number)[]
  setSelectedActivities: React.Dispatch<React.SetStateAction<(string | number)[]>>
}
function getFullNameForEmail(users: user[], email: string) {
  for (let i = 0; i < users.length; i++) {
    if (users[i].email === email) {
      return users[i].first_name + ' ' + users[i].last_name
    }
  }
  return ''
}

const DataTable: React.FC<Props> = ({ selectedActivities, setSelectedActivities }) => {
  const LocationContext = useContext(SelectedLocationContext)
  const [assignUserDialogOpen, setAssignUserDialogOpen] = useState<boolean>(false)

  const {
    globalState: { locations, users, activityMapping, companyActivities },
  } = useContext(StepWizardContext)
  const [showOnlyUnassigned, setShowOnlyUnassigned] = useState<boolean>(false)
  const [gotIt, setGotIt] = useState<boolean>(false)

  const closeCallOut = () => {
    setOpen(false)
    setGotIt(true)
  }

  const [open, setOpen] = useState<boolean>(false)

  let locationActivities: activity[] = []
  for (var key in companyActivities) {
    if (companyActivities.hasOwnProperty(key) && LocationContext.selectedLocations.includes(key)) {
      let tempLocActivities: activity[] = []

      companyActivities[key].forEach((activity) => {
        if (Boolean(activityMapping[key]) && Boolean(activityMapping[key][activity['UAN']])) {
          activity = { ...activity, ...activityMapping[key][activity['UAN']] }
        }
        if (showOnlyUnassigned) {
          if (!activity['tracker_admin'] || !activity['tracker_manager']) {
            tempLocActivities.push(activity)
          }
        } else {
          tempLocActivities.push(activity)
        }
      })

      locationActivities = [...locationActivities, ...tempLocActivities]
    }
  }

  let assignedActivities: number = 0
  locationActivities = locationActivities.map((activ) => {
    if (activ.tracker_manager) {
      assignedActivities = assignedActivities + 1
    }
    return {
      ...activ,
      tracker_admin: getFullNameForEmail(users, activ.tracker_admin as string),
      tracker_manager: getFullNameForEmail(users, activ.tracker_manager as string),
    }
  })

  const handleAssignUserDialogClose = () => {
    setAssignUserDialogOpen(false)
  }
  const handleAssignUserDialogOpen = () => {
    closeCallOut()
    setAssignUserDialogOpen(true)
  }
  const classes = useStyles()

  const columns: ColDef[] = [
    {
      field: 'Name',
      headerName: 'Compliance Activity',
      width: 250,
      renderCell: (params: CellParams) => (
        <Tooltip
          interactive
          title={
            <React.Fragment>
              <b>Compliance Activity: </b>
              {params.row.Name} <br />
              <br /> <b>Description: </b>
              {params.row.Rich_Description ? (
                <span dangerouslySetInnerHTML={{ __html: params.row.Rich_Description }}></span>
              ) : (
                <p>{params.row.Description}</p>
              )}
            </React.Fragment>
          }
          classes={{ tooltip: classes.noMaxWidth }}
          arrow>
          <span className="MuiDataGrid-cell MuiDataGrid-cellLeft">{params.row.Name}</span>
        </Tooltip>
      ),
    },
    { field: 'tracker_admin', headerName: 'Owner', width: 135 },
    { field: 'tracker_manager', headerName: 'Manager', width: 135 },
    { field: 'location', headerName: 'Location', width: 120 },
    { field: 'Category', headerName: 'Category', width: 140 },
    { field: 'Sector', headerName: 'Sector' },
    { field: 'Law Area', headerName: 'Law Area', width: 130 },
    { field: 'Act', headerName: 'Act', width: 190 },
    { field: 'Rules', headerName: 'Rule', width: 190 },
  ]

  useEffect(() => {
    if (!gotIt && locations.length > 0 && Object.keys(activityMapping).length === 0) {
      setOpen(true)
    }
  }, [locations, activityMapping, setOpen, gotIt])
  return (
    <>
      <div className={classes.statContainer}>
        <div className={classes.buttonContainer}>
          <CallOut
            content={<>Select activities from table and assign users</>}
            open={open}
            placement="top"
            onClose={() => closeCallOut()}>
            <div
              onClick={() => {
                if (selectedActivities.length === 0) {
                  toast.error(
                    'Add/Select location and select activities from table to do the assignment',
                    { duration: 4000 },
                  )
                }
              }}>
              <Button
                disableFocusRipple
                disabled={selectedActivities.length === 0}
                className={classes.assignUserButton}
                type="button"
                variant="contained"
                color="primary"
                onClick={handleAssignUserDialogOpen}>
                Assign Users
              </Button>
            </div>
          </CallOut>
          <CenterContent>
            <ToolTip id="assignUsers" activeStepIndex={4} />
          </CenterContent>
        </div>
        <div className={classes.counterContainer}>
          <FormControlLabel
            className={classes.checkboxText}
            control={
              <Switch
                className={classes.switchStyles}
                size="small"
                checked={showOnlyUnassigned}
                onChange={() => {
                  if (!showOnlyUnassigned) {
                    let tempSelectedActivities = [...selectedActivities]
                    locationActivities.forEach((activ) => {
                      if (activ.tracker_manager) {
                        tempSelectedActivities = removeItem(
                          tempSelectedActivities as string[],
                          activ.UAN + '_' + activ.location,
                        )
                      }
                    })

                    setSelectedActivities(tempSelectedActivities)
                  }

                  setShowOnlyUnassigned(!showOnlyUnassigned)
                }}
                name="checkedB"
                color="primary"
              />
            }
            label={<div className={classes.switchText}>Show Only Unassigned</div>}
          />
          <StatusText type="TOTAL" stat={locationActivities.length} />
          <StatusText type="ASSIGNED" stat={assignedActivities} />
        </div>
      </div>
      <Paper>
        <div style={{ height: 400, width: '100%' }}>
          <DataGrid
            rows={locationActivities}
            disableDensitySelector
            columns={columns}
            checkboxSelection
            onSelectionModelChange={(selection: SelectionModelChangeParams) => {
              if ('selectionModel' in selection) {
                setSelectedActivities(selection.selectionModel)
              }
            }}
            components={{
              Toolbar: GridToolbar,
              NoRowsOverlay: customOverlay,
            }}
            rowsPerPageOptions={[100]}
          />
        </div>
      </Paper>
      <AssignUser
        openState={assignUserDialogOpen}
        selectedActivities={selectedActivities}
        handlesDialogClose={handleAssignUserDialogClose}
      />
    </>
  )
}

export default DataTable
