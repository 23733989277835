import { createMuiTheme } from '@material-ui/core/styles'
import overrides from './overrides'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#1D9DB7',
    },
  },
  overrides,
})
export default theme
