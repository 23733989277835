import React, { createContext, useContext } from 'react'
import axios, { AxiosInstance } from 'axios'
import { AuthContext } from './AuthContext'
import publicFetch from '../util/publicFetch'
import { StepWizardContext } from '../Components/OnboardingSteps'

interface FetchProviderProps {
  children: React.ReactNode
}

export interface ContextProps {
  authAxios: AxiosInstance
}
interface queue {
  resolve: (value: unknown) => void
  reject: (reason?: any) => void
}
let isRefreshing: boolean = false
let failedQueue: queue[] = []

const authAxios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})

const FetchContext = createContext({ authAxios })
const { Provider } = FetchContext

const FetchProvider = ({ children }: FetchProviderProps) => {
  const authContext = useContext(AuthContext)
  const { dispatch } = useContext(StepWizardContext)

  const authAxios = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  })

  const processQueue = (error: string | null, token = null) => {
    failedQueue.forEach((prom) => {
      if (error) {
        prom.reject(error)
      } else {
        prom.resolve(token)
      }
    })

    failedQueue = []
  }

  authAxios.interceptors.request.use(
    (config) => {
      config.headers.Authorization = authContext.authState.accessToken
      return config
    },
    (error) => {
      return Promise.reject(error)
    },
  )

  authAxios.interceptors.response.use(
    function (response) {
      return response
    },
    function (error) {
      const originalRequest = error.config
      if (error.response && error.response.status === 401 && !originalRequest._retry) {
        if (isRefreshing) {
          return new Promise(function (resolve, reject) {
            failedQueue.push({ resolve, reject })
          })
            .then((token) => {
              originalRequest.headers['Authorization'] = token
              return axios(originalRequest)
            })
            .catch((err) => {
              return Promise.reject(err)
            })
        }

        originalRequest._retry = true
        isRefreshing = true

        const refreshToken = authContext.authState.refreshToken
        return new Promise(function (resolve, reject) {
          publicFetch
            .post('api_client_onboard/refresh_jwt_token', { refresh_token: refreshToken })
            .then(({ data }) => {
              authContext.setAuthState({
                ...authContext.authState,
                accessToken: data.token.access,
                expiresAt: data.token.expiry,
              })
              axios.defaults.headers.common['Authorization'] = data.token.access
              originalRequest.headers['Authorization'] = data.token.access
              processQueue(null, data.token.access)
              resolve(axios(originalRequest))
            })
            .catch((err) => {
              processQueue(err, null)
              reject(err)
              dispatch({
                type: 'RESET',
              })
              authContext.logout()
            })
            .finally(() => {
              isRefreshing = false
            })
        })
      }

      return Promise.reject(error)
    },
  )
  return (
    <Provider
      value={{
        authAxios,
      }}>
      {children}
    </Provider>
  )
}

export { FetchContext, FetchProvider }
