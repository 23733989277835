import React, { ReactNode } from 'react'
import { Field, ErrorMessage, FieldInputProps } from 'formik'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import Chip from '@material-ui/core/Chip'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(2, 0, 2, 0),
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
    },
    noLabel: {
      marginTop: theme.spacing(3),
    },
    helperText: {
      color: theme.palette.error.main,
    },
  }),
)

export interface SelectItem {
  id: number
  name: string
}

interface SelectInputProps {
  name: string
  items: SelectItem[]
  label: string
  required?: boolean
  fullWidth?: boolean
  variant?: string
  placeholder?: string
  disableOtherThanThis?: number[]
}

interface SelectMuiProps extends FieldInputProps<string> {
  children: ReactNode
  label: string
  required: boolean
  variant: 'outlined' | 'filled' | 'standard' | undefined
  errorText: string
  items: SelectItem[]
  placeholder: string
}

const SelectMui: React.FC<SelectMuiProps> = ({
  children,
  label,
  value,
  name,
  required,
  variant,
  errorText,
  items,
  placeholder,
  onChange,
  onBlur,
}) => {
  const classes = useStyles()
  return (
    <FormControl fullWidth variant={variant} required={required} className={classes.formControl}>
      <InputLabel>{label}</InputLabel>
      <Select
        placeholder={placeholder}
        name={name}
        multiple
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        label={label}
        renderValue={(selected) => {
          const selectedItems: SelectItem[] = items.filter((item) =>
            (selected as number[]).includes(item.id),
          )
          return (
            <div className={classes.chips}>
              {selectedItems.map((item) => (
                <Chip key={item.id} label={item.name} className={classes.chip} />
              ))}
            </div>
          )
        }}>
        {children}
      </Select>
      <FormHelperText className={classes.helperText}>{errorText}</FormHelperText>
    </FormControl>
  )
}

const SelectInput: React.FC<SelectInputProps> = ({
  name,
  items,
  label,
  required = true,
  fullWidth = true,
  variant = 'outlined',
  placeholder,
  disableOtherThanThis = [],
}) => {
  return (
    <Field
      as={SelectMui}
      name={name}
      label={label}
      variant={variant}
      fullWidth={fullWidth}
      required={required}
      items={items}
      placeholder={placeholder}
      errorText={<ErrorMessage name={name} />}>
      {items.map((item) => (
        <MenuItem
          key={item.id}
          value={item.id}
          disabled={disableOtherThanThis.length > 0 && !disableOtherThanThis.includes(item.id)}>
          {item.name}
        </MenuItem>
      ))}
    </Field>
  )
}

export default SelectInput
