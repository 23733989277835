import React from 'react'
import './App.css'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import { AuthProvider } from '../../Context/AuthContext'
import OnBoardingSteps from '../OnboardingSteps'
import { Toaster } from 'react-hot-toast'
import Header from '../Header/Header'
import { FetchProvider } from '../../Context/FetchContext'
import ScrollToTop from '../ScrollToTop'

const App: React.FC = () => {
  return (
    <Router>
      <ScrollToTop />
      <AuthProvider>
        <FetchProvider>
          <div className="App">
            <Toaster toastOptions={{ style: { fontFamily: 'Roboto' } }} />
            <Header />
            <Switch>
              <Route path="/onboarding" component={OnBoardingSteps} />
              <Route component={() => <Redirect to="/onboarding/login" />} />
            </Switch>
          </div>
        </FetchProvider>
      </AuthProvider>
    </Router>
  )
}

export default App
