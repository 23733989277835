import { Overrides } from '@material-ui/core/styles/overrides'

const overrides: Overrides = {
  MuiButton: {
    root: {
      borderRadius: 50,
      padding: '10px 40px',
      textTransform: 'none',
      fontSize: 16,
      fontWeight: 'bold',
      '&:hover': {
        boxShadow: 'none',
      },
      // '&:focus': {
      //   color: 'white',
      //   backgroundColor: '#1D9DB7',
      // },
    },
    contained: {
      border: 'none',
      backgroundColor: '#1D9DB7',
      boxShadow: 'none',
      '&:hover': {
        backgroundColor: 'rgba(52, 182, 195, 0.1)',
        boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.07)',
      },
      '&$focusVisible': {
        backgroundColor: 'rgba(52, 182, 195, 0.1)',
        boxShadow: 'none',
      },
      '&:active': {
        backgroundColor: 'rgba(52, 182, 195, 0.1)',
        boxShadow: 'none',
      },
      '&$disabled': {
        backgroundColor: '#eee',
        boxShadow: 'none',
        border: 'none',
      },
      '&:focus': {
        color: 'white',
        backgroundColor: '#1D9DB7',
      },
    },
    containedPrimary: {
      color: '#fff',
      backgroundColor: '#1D9DB7',
      '&:hover': {
        backgroundColor: '#1D9DB7',
        color: '#fff',
        '@media (hover: none)': {
          backgroundColor: '#1D9DB7',
        },
      },
      '&:focus': {
        color: '#fff',
        backgroundColor: '#1D9DB7',
      },
    },
    textSecondary: {
      color: '#1D9DB7',
      '&:hover': {
        backgroundColor: 'none',
      },
    },
  },
  // MuiButtonBase: {
  //   focusVisible: {
  //     color: 'white',
  //     backgroundColor: '#1D9DB7',
  //   },
  // },
  MuiTooltip: {
    tooltip: {
      fontSize: '0.8em',
      zIndex: 9999,
    },
  },
}

export default overrides
