import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Form, Formik, FormikHelpers } from 'formik'
import TextInput from '../../Components/FormikMuiFields/TextInput'
import IconButton from '@material-ui/core/IconButton/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { createStyles, makeStyles, Theme } from '@material-ui/core'
import * as yup from 'yup'
import Typography from '@material-ui/core/Typography'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { user as UserProps } from '../../Types/user'
import { useContext, useState } from 'react'
import { StepWizardContext } from '../../Components/OnboardingSteps'
import LoadingButton from '../LoadingButton'
import toast from 'react-hot-toast'
import { FetchContext } from '../../Context/FetchContext'
import { userRoles } from '../../util/userRoles'
import ToolTip from '../ToolTip'
import CenterContent from '../CenterContent'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  }),
)

interface AddUserProps {
  openState: boolean
  role: string
  email?: string
  handlesDialogClose: () => void
  setfieldvalue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => void | (() => void)
}
const UserFormDialog: React.FC<AddUserProps> = ({
  openState,
  role,
  email = '',
  handlesDialogClose,
  setfieldvalue,
}) => {
  const { globalState, dispatch } = useContext(StepWizardContext)
  const fetchContext = useContext(FetchContext)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  let initialVals: UserProps = {
    first_name: '',
    last_name: '',
    email: email,
    role: role,
    is_reviewer: role === 'tracker_manager',
  }
  const classes = useStyles()
  let validationSchema = yup.object().shape({
    first_name: yup.string().required('First Name is a required field'),
    last_name: yup.string().required('Last Name is a required field'),
    email: yup
      .string()
      .email()
      .lowercase()
      .required('Email is a required field')
      .notOneOf(
        globalState.users.map((u) => u.email.toLowerCase()),
        'User already exist for this email id',
      ),
    is_reviewer: yup.boolean(),
    role: yup.string(),
  })
  return (
    <Dialog open={openState} onClose={handlesDialogClose} aria-labelledby="form-dialog-title">
      <DialogTitle disableTypography className={classes.root}>
        <Typography variant="h6">Add {userRoles[role]}</Typography>
        <IconButton aria-label="close" className={classes.closeButton} onClick={handlesDialogClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Formik
          initialValues={initialVals}
          validationSchema={validationSchema}
          onSubmit={(values: UserProps, { setErrors }: FormikHelpers<UserProps>) => {
            setIsLoading(true)
            fetchContext.authAxios
              .post(`/api_client_onboard/user`, {
                users: { [values.email]: values },
              })
              .then((res) => {
                dispatch({ type: 'ADD_USER', payload: [values] })
                if (role !== 'senior_manager') {
                  setfieldvalue(role, values.email)
                }

                handlesDialogClose()
                setIsLoading(false)
                toast.success('User added Successfully')
              })
              .catch((error) => {
                if (error.response.status === 400 && values.email in error.response.data.error) {
                  setErrors(error.response.data.error[values.email])
                } else {
                  toast.error('Refresh and try again!')
                }
                setIsLoading(false)
              })
          }}>
          {({
            handleChange,
            handleBlur,
            values,
            touched,
            errors,
            isSubmitting,
            isValid,
            dirty,
            initialValues,
          }) => (
            <Form>
              <TextInput
                name="first_name"
                required
                margin="normal"
                label="First Name"
                fullWidth
                autoFocus
                autoComplete="off"
                error={touched.first_name && Boolean(errors.first_name)}
              />
              <TextInput
                name="last_name"
                required
                margin="normal"
                label="Last Name"
                fullWidth
                autoComplete="off"
                error={touched.last_name && Boolean(errors.last_name)}
              />
              <TextInput
                name="email"
                required
                margin="normal"
                label="Email"
                fullWidth
                autoComplete="off"
                error={touched.email && Boolean(errors.email)}
              />
              {role !== 'senior_manager' && (
                <>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.is_reviewer}
                        onChange={handleChange}
                        name="is_reviewer"
                        color="primary"
                      />
                    }
                    label={
                      <CenterContent>
                        Reviewer
                        <ToolTip id="reviewer" activeStepIndex={5} />
                      </CenterContent>
                    }
                  />
                </>
              )}
              <DialogActions>
                <LoadingButton
                  loading={isLoading}
                  type="submit"
                  color="primary"
                  disabled={!(dirty && isValid) || isLoading}
                  variant="contained"
                  size="large">
                  Add
                </LoadingButton>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  )
}

export default UserFormDialog
