import React from 'react'
import styled from '@material-ui/styles/styled'

interface Props {
  title: string
  body: string
}

const Container = styled('div')({
  display: 'flex',
  marginBottom: '30px',
})

const ContainerLeft = styled('div')({
  flex: 1,
})

// const ContainerRight = styled('div')({
//   flex: 1,
// })

const Title = styled('div')({
  fontFamily: 'Nunito',
  fontStyle: 'normal',
  fontWeight: 900,
  fontSize: '18px',
  lineHeight: '32px',
  color: '#000000',
})

const Body = styled('div')({
  fontFamily: 'Nunito',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: '15px',
  lineHeight: 'auto',
  color: '#000000',
  opacity: 0.8,
  whiteSpace: 'pre-wrap',
  overflow: 'auto',
})

const HelperContentLayout: React.FC<Props> = ({ title, body }) => {
  return (
    <Container>
      <ContainerLeft>
        <Title>{title}</Title>
        <Body>{body}</Body>
      </ContainerLeft>
      {/*<ContainerRight>right</ContainerRight>*/}
    </Container>
  )
}

export default HelperContentLayout
