import React, { useContext } from 'react'
import { AuthContext } from '../../Context/AuthContext'
import { Redirect, Route } from 'react-router-dom'

interface Props {
  component: any
  exact?: any
  path: string
}

const ProtectedRoute: React.FC<Props> = ({ component: Component, ...rest }) => {
  const { isAuthenticated } = useContext(AuthContext)

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated() ? <Component {...props} /> : <Redirect to="/onboarding/login" />
      }
    />
  )
}

export default ProtectedRoute
