import React from 'react'
import styled from '@material-ui/styles/styled'

interface Props {}

const Container = styled('div')({
  display: 'flex',
  alignItems: 'center',
})

const CenterContent: React.FC<Props> = ({ children }) => {
  return <Container>{children}</Container>
}

export default CenterContent
