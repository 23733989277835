import React from 'react'
import Button from '@material-ui/core/Button'
import { ButtonProps } from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  spinner: {
    margin: theme.spacing(0, 2, 0, 2),
    color: 'grey',
  },
}))

interface Props {
  loading: boolean
}

const LoadingButton: React.FC<Props & ButtonProps> = (props) => {
  const { children, loading, ...rest } = props
  const classes = useStyles()
  return (
    <Button {...rest}>
      {children}
      {loading && <CircularProgress size={20} className={classes.spinner} />}
    </Button>
  )
}
export default LoadingButton
