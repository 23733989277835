const helperText = [
  {
    login: [
      {
        id: 'addLocation',
        titleText: 'Add Locations',
        hoverText:
          'Location refers to an establishment of legal Entity. The establishment shall mean a Corporate office or a Branch office or a Factory or a Warehouse, etc.',
        detailedText:
          'Compliance obligation(s) differ based on the type of Location. E.g, based on the type of Location a client may have to procure separate Registration or Licenses.\nAdding a location, generates activities for the next one year',
      },
    ],
  },
  {
    'add-company': [
      {
        id: 'addCompany',
        titleText: 'Add Company',
        hoverText: 'Add Company Details',
        detailedText:
          'Name- Please fill in the Registered legal entity name\n\nUEN- Please fill in the Unique Entity Number as obtained from ACRA\n\nDate of incorporation- Please fill in the date on which certificate of incorporation is issued by ACRA\n\nEntity Type- Please choose the applicable entity type as registered with ACRA \n\nSector: Please select in the appropriate Sector Value from the drop-down list\n\nSector Activity: Please select in the appropriate Sector Activity Value(s) from the drop-down list',
      },
    ],
  },
  {
    'add-compliances': [
      {
        id: 'addLocation',
        titleText: 'Add Locations',
        hoverText:
          'Location refers to an establishment of legal Entity. The establishment shall mean a Corporate office or a Branch office or a Factory or a Warehouse, etc.',
        detailedText:
          'Location refers to an establishment of legal Entity. The establishment shall mean a Corporate office or a Branch office or a Factory or a Warehouse, etc.',
      },
      {
        id: 'officeType',
        titleText: 'Office Type',
        hoverText: 'Please select all the different office types that apply to this location.',
        detailedText:
          'Branch: An extension of a foreign company registered in Singapore.\n\nCorporate : The principal operating entity.\n\nRegistered : The legal address of the company which may be a company secretarial firm or be located at the Corporate office.',
      },
      {
        id: 'addSeniorManager',
        titleText: 'Senior Managers',
        hoverText:
          'Senior managers are responsible for overseeing/monitoring the organization wide compliance activities',
        detailedText:
          'Senior Managers are responsible for overseeing/monitoring the organization-wide compliance activities and have access to all Tasks [but have no execution rights].  Typically, the role is assigned to C-Suite and C-Suite -1 executives or  those with senior specialist roles.',
      },
      {
        id: 'complianceActivities',
        titleText: 'Compliance Activities',
        hoverText: 'add compliance activities',
        detailedText:
          'Compliance activity refers to a compliance obligation as applicable to an entity.',
      },
      {
        id: 'assignUsers',
        titleText: 'Assign Users',
        hoverText:
          'Every activity must be assigned to an Owner and to a Manager. Both functions will receive notifications regarding the status of all assigned tasks. The Manager role has full Owner privileges plus the right to approve tasks submitted by the Owner.',
        detailedText:
          'Every activity must be assigned to an Owner and to a Manager. Both functions will receive notifications regarding the status of all assigned tasks. The Manager role has full Owner privileges plus the right to approve tasks submitted by the Owner.\n\n- Compliance Owner - The person responsible for completing the compliance, uploading the proof or certifying that a proof is not required.\n\n- Compliance Manager - The person responsible for overseeing compliance tasks in a particular location and law area and the approval of proofs. Includes full Owner privileges of proof upload and certification.',
      },
      {
        id: 'reviewer',
        titleText: 'Reviewer',
        hoverText:
          'Reviewer is an add-on right that can be assigned to a User (preferably a Compliance Manager). This enables the user to change the Status of a Compliance Activity.',
        detailedText:
          'Reviewer is an add-on right that can be assigned to a User (preferably a Compliance Manager). This enables the user to change the Status of a Compliance Activity.',
      },
    ],
  },
  {
    'review-onboarding': [
      {
        id: 'notifications',
        titleText: 'Notifications',
        hoverText:
          'Notifications or reminders sent from Complius™ to registered users (Owners and Managers) containing details of compliance activities that are due and actions to be performed. The default setting is that reminders are automatically sent to the User on T-X Day where T refers to the due date of the activity and X refers to the number of days before the due date E.g. T-15 Days.',
        detailedText: '',
      },
    ],
  },
]

export default helperText
