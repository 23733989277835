import React from 'react'
import FormCard from '../FormCard/FormCard'
import CardItem, { data } from './CardItem'
import reviewNameIcon from '../../assets/review_name.svg'
import reviewPaidUpCapitalIcon from '../../assets/review_paid_up_capital.svg'
import reviewDateOfIncIcon from '../../assets/review_data_of_inc.svg'
import reviewSectorIcon from '../../assets/review_sector.svg'
import reviewIndustryIcon from '../../assets/review_industry.svg'
import reviewListIcon from '../../assets/review_list.svg'
import { styled } from '@material-ui/core/styles'
import { useContext } from 'react'
import { StepWizardContext } from '../OnboardingSteps'
import { useState } from 'react'
import { useEffect } from 'react'
import publicFetch from '../../util/publicFetch'
import { Sectors } from '../../Pages/AddCompany'
import { getFormattedDate } from '../../util/dateUtil'
import { CompanyActivities } from '../../Types/activity'

interface Props {}

const Container = styled('div')({})

const locationActivityCount = (locObject: CompanyActivities) => {
  let count: number = 0
  for (let locName in locObject) {
    count = count + locObject[locName].length
  }
  return count
}

const BasicInformation: React.FC<Props> = () => {
  const { globalState } = useContext(StepWizardContext)
  let totalCount: number = locationActivityCount(globalState.companyActivities)
  let data: data[] = [
    {
      id: 1,
      img: reviewNameIcon,
      label: 'name',
      desc: globalState.company.name,
    },
    {
      id: 2,
      img: reviewNameIcon,
      label: 'UEN',
      desc: globalState.company.cin,
    },
    {
      id: 3,
      img: reviewDateOfIncIcon,
      label: 'date of incorporation',
      desc: Boolean(globalState.company.date_of_inc)
        ? getFormattedDate(globalState.company.date_of_inc)
        : '',
    },
    {
      id: 4,
      img: reviewPaidUpCapitalIcon,
      label: 'paid up capital',
      desc:
        globalState.company.paidup_capital +
        ' ' +
        (globalState.company.paidup_capital ? globalState.company.puc_currency : ''),
    },
    {
      id: 5,
      img: reviewIndustryIcon,
      label: 'Entity Type',
      details: [
        {
          id: 1,
          name: globalState.company.entity_type,
        },
      ],
    },
    {
      id: 6,
      img: reviewSectorIcon,
      label: 'Sector',
      details: [],
    },
    {
      id: 7,
      img: reviewIndustryIcon,
      label: 'Sector Activity',
      details: [
        {
          id: 1,
          name: globalState.company.sector_activity,
        },
      ],
    },
    {
      id: 8,
      img: reviewListIcon,
      label: 'Total Activities',
      details: [
        {
          id: 1,
          name: totalCount.toString(),
        },
      ],
    },
  ]

  const [companyInfo, setCompanyInfo] = useState<data[]>(data)

  useEffect(() => {
    publicFetch
      .get(`/api_client_onboard/get_sectors`)
      .then((res) => {
        const sectorList: Sectors[] = res.data.result.filter((sector: Sectors) => {
          return globalState.company.sector.includes(sector.id)
        })
        setCompanyInfo((prevData) => {
          data = [...prevData]
          data[5] = {
            id: 6,
            img: reviewSectorIcon,
            label: 'Sector',
            details: sectorList,
          }
          return data
        })
      })
      .catch((error) => {})
  }, [])

  return (
    <Container>
      <FormCard isReviewCard header="Company Information">
        <CardItem data={companyInfo} />
      </FormCard>
    </Container>
  )
}

export default BasicInformation
