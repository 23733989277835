import React, { useContext, useState } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import ListSubheader from '@material-ui/core/ListSubheader'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import EditIcon from '@material-ui/icons/Edit'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import IconButton from '@material-ui/core/IconButton/IconButton'
import LocationFormDialog, { OfficeType } from '../../Components/LocationFormDialog'
import { StepWizardContext } from '../OnboardingSteps'
import { SelectedLocationContext } from '../../Context/SelectedLocations'
import { Location } from '../../Types/location'
import ToolTip from '../ToolTip'
import CenterContent from '../CenterContent'
import CallOut from '../CallOut'
import { CompanyActivities } from '../../Types/activity'
import { removeItem } from '../../util/common'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
      margin: theme.spacing(6, 0, 0, 0),
      marginBottom: '30px',
    },
    noLocations: {
      fontSize: '2rem',
      cursor: 'default',
      '&:hover': {
        textDecoration: 'none',
        backgroundColor: 'white',
      },
    },
  }),
)

interface Props {
  selectedActivities: (string | number)[]
  setSelectedActivities: React.Dispatch<React.SetStateAction<(string | number)[]>>
}
interface DialogState {
  name: string
  address: string
  office_type: OfficeType[]
  dialogOpen: boolean
}

const updateSelectedActivites = (
  deletedLocName: string,
  selectedActivities: (string | number)[],
  setSelectedActivities: React.Dispatch<React.SetStateAction<(string | number)[]>>,
  companyActivities: CompanyActivities,
) => {
  if (deletedLocName in companyActivities) {
    companyActivities[deletedLocName].forEach((activity) => {
      selectedActivities = removeItem(
        selectedActivities as string[],
        activity['UAN'] + '_' + deletedLocName,
      )
    })
    setSelectedActivities(selectedActivities)
  }
}
const LocationList: React.FC<Props> = ({ selectedActivities, setSelectedActivities }) => {
  const classes = useStyles()
  const LocationContext = useContext(SelectedLocationContext)
  const { globalState } = useContext(StepWizardContext)
  const [open, setOpen] = useState<boolean>(globalState.locations.length === 0)
  let locList: Location[]
  if (globalState.locations !== null) {
    locList = [...globalState.locations]
  } else {
    locList = []
  }

  const [dialogState, setDialogState] = useState<DialogState>({
    name: '',
    address: '',
    office_type: [],
    dialogOpen: false,
  })

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    name: string,
  ) => {
    if (LocationContext.selectedLocations.includes(name)) {
      LocationContext.setSelectedLocations(
        LocationContext.selectedLocations.filter((oldName) => oldName !== name),
      )
      updateSelectedActivites(
        name,
        selectedActivities,
        setSelectedActivities,
        globalState.companyActivities,
      )
    } else {
      LocationContext.setSelectedLocations([...LocationContext.selectedLocations, name])
    }
  }
  const handleLocationDialogOpen = (
    name: string,
    address: string,
    office_type: OfficeType[],
    dialogOpen: boolean,
  ) => {
    setOpen(false)
    setDialogState({
      name: name,
      address: address,
      office_type: office_type,
      dialogOpen: true,
    })
  }

  const handleLocationDialogClose = () => {
    setDialogState({
      name: '',
      address: '',
      office_type: [],
      dialogOpen: false,
    })
  }

  return (
    <>
      <List
        className={classes.root}
        component="nav"
        aria-label="secondary mailbox folder"
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            <CenterContent>
              Locations <ToolTip id="addLocation" activeStepIndex={0} />
            </CenterContent>
            <ListItemSecondaryAction>
              <CallOut
                content={<>Start with adding locations</>}
                open={open}
                placement="top"
                onClose={() => setOpen(false)}>
                <IconButton
                  edge="end"
                  aria-label="add"
                  onClick={() => handleLocationDialogOpen('', '', [], true)}>
                  <AddCircleOutlineIcon />
                </IconButton>
              </CallOut>
            </ListItemSecondaryAction>
          </ListSubheader>
        }>
        <Divider />
        <div style={{ maxHeight: '240px', overflow: 'auto' }}>
          {locList.length > 0 ? (
            locList.map((loc) => {
              return (
                <ListItem
                  button
                  key={loc.name}
                  selected={LocationContext.selectedLocations.includes(loc.name)}
                  onClick={(event) => handleListItemClick(event, loc.name)}>
                  <ListItemText primary={loc.name} />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() =>
                        handleLocationDialogOpen(loc.name, loc.address, loc.office_type, true)
                      }>
                      <EditIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              )
            })
          ) : (
            <ListItem
              button
              key={'No_locations'}
              className={classes.noLocations}
              disableRipple
              disableTouchRipple>
              <ListItemText primary="No locations added." />
            </ListItem>
          )}
        </div>
      </List>
      <LocationFormDialog
        name={dialogState.name}
        address={dialogState.address}
        office_type={dialogState.office_type.map((o) => o.id)}
        country={globalState.defaultValues.country}
        state={globalState.defaultValues.state}
        city={globalState.defaultValues.city}
        openState={dialogState.dialogOpen}
        handlesDialogClose={handleLocationDialogClose}
      />
    </>
  )
}

export default LocationList
