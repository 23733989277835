import React, { ReactElement } from 'react'
import { Field, ErrorMessage, FieldInputProps } from 'formik'
import {
  Checkbox,
  CheckboxProps,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
} from '@material-ui/core'

interface Props {
  name: string
  label: ReactElement | string
}

interface CheckBoxMuiProps extends FieldInputProps<boolean> {
  label: ReactElement | string
  errorText: string
}

const CheckBoxMui: React.FC<CheckBoxMuiProps> = ({
  label,
  value,
  name,
  onChange,
  onBlur,
  errorText,
  ...rest
}) => {
  return (
    <FormControl>
      <FormGroup>
        <FormControlLabel
          control={<Checkbox checked={value} name={name} {...rest} />}
          label={label}
          onChange={onChange}
          onBlur={onBlur}
        />
      </FormGroup>
      <FormHelperText error={true}>{errorText}</FormHelperText>
    </FormControl>
  )
}

const CheckBoxInput: React.FC<Props & CheckboxProps> = ({ name, ...rest }) => {
  return <Field as={CheckBoxMui} name={name} errorText={<ErrorMessage name={name} />} {...rest} />
}

export default CheckBoxInput
