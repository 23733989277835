import { Button } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import SeniorManagerList from '../SeniorManagerList'
import { styled } from '@material-ui/core/styles'
import ToolTip from '../ToolTip'
import CenterContent from '../CenterContent'
import CallOut from '../CallOut'
import { useContext } from 'react'
import { StepWizardContext } from '../OnboardingSteps'
import { user } from '../../Types/user'
import toast from 'react-hot-toast'

interface Props {}

const StyledButton = styled(Button)({
  color: '#1D9DB7',
  background: 'white',
  border: '1px solid #1D9DB7',
  '&:hover': {
    color: '#1D9DB7',
    background: 'white',
    border: '1px solid #1D9DB7',
  },
})

const SeniorManagerTab: React.FC<Props> = () => {
  const {
    globalState: { locations, users, activityMapping },
  } = useContext(StepWizardContext)
  const [seniorManagerDialogOpen, setSeniorManagerDialogOpen] = useState<boolean>(false)
  const [gotIt, setGotIt] = useState<boolean>(false)

  const closeCallOut = () => {
    setOpen(false)
    setGotIt(true)
  }
  let seniorManagers: user[] = users.filter((user: user) => {
    return user['role'] === 'senior_manager'
  })
  const [open, setOpen] = useState<boolean>(false)

  const handleSrManagerDialogClose = () => {
    setSeniorManagerDialogOpen(false)
  }
  const handleSrManagerDialogOpen = () => {
    closeCallOut()
    setSeniorManagerDialogOpen(true)
  }
  useEffect(() => {
    if (
      !gotIt &&
      locations.length > 0 &&
      seniorManagers.length === 0 &&
      Object.keys(activityMapping).length > 0
    ) {
      setOpen(true)
    }
  }, [locations, seniorManagers, activityMapping, setOpen, gotIt])

  return (
    <>
      <CenterContent>
        <div
          onClick={() => {
            if (Object.keys(activityMapping).length === 0) {
              toast.error('Assign at least one activitiy to add senior managers', {
                duration: 4000,
              })
            }
          }}>
          <CallOut
            content={<>Add one or more Senior Managers</>}
            open={open}
            placement="bottom"
            onClose={() => closeCallOut()}>
            <StyledButton
              disabled={Object.keys(activityMapping).length === 0}
              onClick={handleSrManagerDialogOpen}
              disableFocusRipple
              variant="contained"
              color="primary">
              Add Senior Managers
            </StyledButton>
          </CallOut>
        </div>

        <ToolTip id="addSeniorManager" activeStepIndex={2} />
      </CenterContent>
      <SeniorManagerList
        openState={seniorManagerDialogOpen}
        handlesDialogClose={handleSrManagerDialogClose}
      />
    </>
  )
}

export default SeniorManagerTab
