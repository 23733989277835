import React, { ReactElement } from 'react'
import { styled } from '@material-ui/core/styles'

export type data = {
  id: number
  img: any
  label: string
  desc: string
  time: string | ReactElement
  color: string
}

interface Props {
  data: data[]
}

const Container = styled('div')({
  display: 'flex',
  alignItems: 'flex-start',
  backgroundColor: 'white',
  borderRadius: '4px',
  padding: '10px',
  borderLeft: '4px solid #2196F3',
  fontFamily: 'Nunito',
  marginBottom: '10px',
})

const Label = styled('p')({
  fontFamily: 'Nunito',
  fontStyle: 'normal',
  fontWeight: 800,
  fontSize: '15px',
  lineHeight: '20px',
  letterSpacing: '0.1px',
  color: '#323C47',
  margin: '0',
  marginBottom: '5px',
})

const Description = styled('p')({
  fontFamily: 'Nunito',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '13px',
  lineHeight: '18px',
  letterSpacing: '0.2px',
  textTransform: 'capitalize',
  color: '#8E8E8E',
  margin: 0,
  marginBottom: '10px',
})

const DateValue = styled('p')({
  fontFamily: 'Nunito',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '10px',
  lineHeight: '18px',
  letterSpacing: '0.2px',
  textTransform: 'uppercase',
  color: '#000000',
  margin: 0,
})

const TextContainer = styled('div')({})

const Image = styled('img')({
  marginRight: '10px',
})

const NotificationCard: React.FC<Props> = ({ data }) => {
  return (
    <>
      {data?.map(({ id, label, img, desc, time, color }) => (
        <Container key={id} style={{ borderLeft: `4px solid ${color}` }}>
          <Image src={img} />
          <TextContainer>
            <Label>{label}</Label>
            <Description>{desc}</Description>
            <DateValue>{time}</DateValue>
          </TextContainer>
        </Container>
      ))}
    </>
  )
}

export default NotificationCard
