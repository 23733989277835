import React, { useContext } from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import HelpOutline from '@material-ui/icons/HelpOutline'
import styled from '@material-ui/styles/styled'
import { Button } from '@material-ui/core'
import { StepWizardContext } from './OnboardingSteps'

const TooltipCustom = styled(Tooltip)({
  marginLeft: '5px',
})

const TooltipCustomText = styled('div')({
  marginTop: '5px',
  fontSize: '0.8rem',
  lineHeight: 'auto',
})

const Icon = styled(HelpOutline)({
  height: 20,
  color: '#5d5d5d',
})

const ReadMoreButton = styled(Button)({
  fontSize: 10,
  color: 'white',
})

interface Props {
  id: string
  activeStepIndex?: number
}

const ToolTip: React.FC<Props> = ({ id, activeStepIndex }) => {
  const { globalState, dispatch } = useContext(StepWizardContext)
  return (
    <TooltipCustom
      title={
        <div>
          <TooltipCustomText>
            {globalState.helperTextData.map((el) => el.id === id && el.hoverText)}
          </TooltipCustomText>
          <div>
            {globalState.helperTextData.filter((el) => el.id === id && el.detailedText).length >
              0 && (
              <ReadMoreButton
                variant="text"
                onClick={() =>
                  dispatch({
                    type: 'SET_HELPER_TEXT',
                    payload: { openModal: true, activeStep: activeStepIndex ? activeStepIndex : 0 },
                  })
                }>
                Read more
              </ReadMoreButton>
            )}
          </div>
        </div>
      }
      interactive>
      <Icon />
    </TooltipCustom>
  )
}

export default ToolTip
