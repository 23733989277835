import React, { ReactElement } from 'react'
import { memo } from 'react'

interface Props {}

const Video = ({}: Props): ReactElement => {
  return (
    <iframe
      width="560"
      height="315"
      src="https://www.youtube.com/embed/6WEd6m71k_g"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  )
}
export default memo(Video)
