// Taken from https://stackoverflow.com/a/64040075/4065350
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Fade,
  makeStyles,
  Paper,
  Popper,
  PopperPlacementType,
  Typography,
} from '@material-ui/core'
import React, { ReactElement } from 'react'

interface Props {
  content: ReactElement
  children: ReactElement
  open: boolean
  onClose?: () => void
  arrow?: boolean
  placement?: PopperPlacementType
}

const useStyles = makeStyles((theme) => {
  const color = theme.palette.background.paper
  return {
    popoverRoot: {
      backgroundColor: color,
      maxWidth: 1000,
      boxShadow: theme.shadows[2],
    },
    content: {
      padding: theme.spacing(2),
    },

    popper: {
      zIndex: 1000,
      '&[x-placement*="bottom"] $arrow': {
        top: 0,
        left: 0,
        marginTop: '-0.71em',
        marginLeft: 4,
        marginRight: 4,
        '&::before': {
          transformOrigin: '0 100%',
        },
      },
      '&[x-placement*="top"] $arrow': {
        bottom: 0,
        left: 0,
        marginBottom: '-0.71em',
        marginLeft: 4,
        marginRight: 4,
        '&::before': {
          transformOrigin: '100% 0',
        },
      },
      '&[x-placement*="right"] $arrow': {
        left: 0,
        marginLeft: '-0.71em',
        height: '1em',
        width: '0.71em',
        marginTop: 4,
        marginBottom: 4,
        '&::before': {
          transformOrigin: '100% 100%',
        },
      },
      '&[x-placement*="left"] $arrow': {
        right: 0,
        marginRight: '-0.71em',
        height: '1em',
        width: '0.71em',
        marginTop: 4,
        marginBottom: 4,
        '&::before': {
          transformOrigin: '0 0',
        },
      },
    },

    arrow: {
      overflow: 'hidden',
      position: 'absolute',
      width: '1em',
      height: '0.71em' /* = width / sqrt(2) = (length of the hypotenuse) */,
      boxSizing: 'border-box',
      color,
      '&::before': {
        content: '""',
        margin: 'auto',
        display: 'block',
        width: '100%',
        height: '100%',
        boxShadow: theme.shadows[2],
        backgroundColor: 'currentColor',
        transform: 'rotate(45deg)',
      },
    },
    cardRoot: {
      minWidth: 275,
    },
  }
})

const CallOut = ({
  placement = 'top',
  arrow = true,
  open,
  onClose = () => {},
  content,
  children,
}: Props) => {
  const classes = useStyles()
  const [arrowRef, setArrowRef] = React.useState<HTMLElement | null>(null)
  const [childNode, setChildNode] = React.useState<HTMLElement | null>(null)

  return (
    <div>
      {React.cloneElement(children, { ...children.props, ref: setChildNode })}
      <Popper
        open={open}
        anchorEl={childNode}
        placement={placement}
        transition
        className={classes.popper}
        modifiers={{
          preventOverflow: {
            enabled: true,
            boundariesElement: 'window',
          },
          arrow: {
            enabled: arrow,
            element: arrowRef,
          },
        }}>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper elevation={0}>
              <Paper className={classes.popoverRoot} elevation={0}>
                {arrow ? <span className={classes.arrow} ref={setArrowRef} /> : null}

                <Card className={classes.cardRoot} variant="outlined">
                  <CardContent>
                    <Typography variant="subtitle1" component="p">
                      {content}
                    </Typography>
                  </CardContent>
                  <CardActions style={{ display: 'flex' }}>
                    <Button size="small" onClick={onClose} style={{ marginLeft: 'auto' }}>
                      GOT IT
                    </Button>
                  </CardActions>
                </Card>
              </Paper>
            </Paper>
          </Fade>
        )}
      </Popper>
    </div>
  )
}

export default CallOut
