import apiClient from '../util/publicFetch'
import { toast } from 'react-hot-toast'
import { Values } from '../Pages/VerifyOTP'
import { Dispatch, SetStateAction } from 'react'

const resendOtpHandler = (
  values: Values,
  setErrors: (fields: { [field: string]: string }) => void,
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
  setEmailValidated: Dispatch<SetStateAction<boolean>>,
  setOTPResendTries: Dispatch<SetStateAction<number>>,
) => {
  const headers = { 'Access-Control-Allow-Origin': '*' }
  // setFieldValue('otp', '')
  apiClient
    .post(`/api_client_onboard/send_otp`, values, { headers })
    .then(() => {
      setEmailValidated(true)
      toast.success('OTP Sent')
      setOTPResendTries((OTPResendTries) => OTPResendTries - 1)
    })
    .catch((error) => {
      setEmailValidated(false)
      if (error.response && error.response.status === 400) {
        setErrors(error.response.data.error)
      } else {
        console.error('There was an error!', error)
        toast.error('Something went wrong. Try again later.')
      }
    })
}

export default resendOtpHandler
